<footer class="site-footer footer-large footer-dark text-white footer-style1" >


    <!-- FOOTER BLOCKES START -->
    <div class="footer-top bg-no-repeat bg-bottom-right" style="background-image:url(assets/images/background/footer-bg.png)">
        <div class="container">
            <div class="row">

                <div class="col-lg-3 col-md-12 col-sm-12">
                    <div class="footer-h-left">
                        <div class="widget widget_about">
                            <div class="logo-footer clearfix d-none d-md-block">
                                <a routerLink="/index"><img id="footerLogo1" src="assets/images/logo-white.png" alt="" ></a>
                            </div>
                            <p>{{footerText}}</p>
                         </div>
                        <div class="widget recent-posts-entry">
                           <ul class="widget_address">
                                <li><i class="fa fa-map-marker"></i>Ankara Üniversitesi Rektörlüğü, Dögol Caddesi 06100
                                  Beşevler / Ankara / Türkiye</li>
                                <li><i class="fa fa-envelope"></i>ankara@ankara.edu.tr</li>
                                <li> <i class="fa fa-phone"></i>+90 (312) 2126040</li>
                            </ul>
                        </div>
                        <ul class="social-icons  wt-social-links footer-social-icon">
                            <li><a target="_blank" href="https://www.instagram.com/ankara.uni" class="fa fa-instagram"></a></li>

                            <li><a target="_blank" href="https://www.facebook.com/AnkaraUniversitesi" class="fa fa-facebook"></a></li>
                            <li><a target="_blank" href="https://twitter.com/ankarauni" class="fa fa-twitter"></a></li>
                            <li><a target="_blank" href="https://www.youtube.com/ankarauni" class="fa fa-youtube"></a></li>
                        </ul>
                    </div>

                </div>

                <div class="col-lg-9 col-md-12 col-sm-12 d-none d-md-block">
                    <div class="row footer-h-right" *ngIf="navData != null">
                      <div class="col-lg-3 col-md-3 col-6" *ngFor="let navChild of navData?.child.slice(0, 4); let i = index; let count = count">
                        <div class="widget widget_services">
                            <h5 class="widget-title">{{navChild.menuName}}</h5>
                            <ul *ngIf="navChild.child != null">
                                <li *ngFor="let nav of navChild.child.slice(0, 8); let j = index">
                                    <a href="{{ nav.url }}">{{ nav.menuName }}</a>
                                </li>
                            </ul>
                        </div>
                        <!-- altıncı döngüdeyken işlemi sonlandır -->
                        <ng-container *ngIf="i === 3">
                            <div *ngIf="count === undefined; else exitLoop"></div>
                        </ng-container>
                        <ng-template #exitLoop></ng-template>
                    </div>



                    </div>

                    <div class="widget widget_newsletter">
                        <h3 class="widget-title">{{currentLang=='tr-TR'?'Aramak İsteğiniz Kelimeyi Giriniz':'CANNOT FIND WHAT YOU ARE LOOKING FOR?'}}
                        </h3>
                            <p>{{currentLang=='tr-TR'?'Aramak İsteğiniz Kelimeyi Giriniz':'Search'}}</p>
                            <div class="newsletter-input">
                              <form
                              role="search"
                              id="searchform"
                              action="arama/search"
                              name="q"
                              method="get"
                              class="radius-xl"
                              (ngSubmit)="search()"
                            >
                              <div class="input-group">
                                <input id="email" type="text" class="form-control" name="q" placeholder="{{currentLang=='tr-TR'?'Ara':'Search'}}">
                                <div class="input-group-append">
                                  <button type="submit" class="input-group-text nl-search-btn text-black site-bg-primary title-style-2">{{currentLang=='tr-TR'?'Ara':'Search'}}</button>
                                </div>
                              </div>
                              </form>
                            </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
    <!-- FOOTER COPYRIGHT -->

    <div class="footer-bottom">
      <div class="container">
        <div class="wt-footer-bot-left d-flex justify-content-between">
            <span class="copyrights-text">Copyright © 2024 <span class="site-text-primary">Ankara Üniversitesi </span></span>
            <ul class="copyrights-nav  ">


                <li><a data-toggle="modal" data-target="#exampleModal1">Website İstek/Şikayet/Öneri</a></li>
            </ul>
        </div>
      </div>
    </div>

<!-- Modal -->
<div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModal1Label" aria-hidden="true" >
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModal1Label" style="color: black;">Website İstek/Şikayet/Öneri</h5>

      </div>
      <div class="modal-body">
        <form>
          <div class="mb-3">
            <label for="icerikTalepEmail" class="form-label" style="color: black;">İçerik Yayınlama Talepleri Email</label>
            <input type="email" class="form-control" id="icerikTalepEmail" value="ankara@ankara.edu.tr" readonly>
          </div>
          <div class="mb-3">
            <label for="webSiteEmail" class="form-label" style="color: black;">Web Sitesi Öneri ve Şikayetleri Email</label>
            <input type="email" class="form-control" id="webSiteEmail" value="rektorlukweb@ankara.edu.tr" readonly>
          </div>
          <div class="mb-3">
            <label for="tasarimKodlama" class="form-label" style="color: black;">Tasarım, Kodlama</label>
            <label for="kurumYil" class="form-label" style="color: black;">2024 - Ankara Üniversitesi Rektörlüğü</label>
          </div>


        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Kapat</button>
      </div>
    </div>
  </div>
</div>
</footer>
