// LangService içinde
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './local-storage-serves';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  constructor(private storageService: StorageService){

  }
  private currentLangCode = new BehaviorSubject<string>(this.storageService.getData("siteLanguage") || 'tr-TR'); // Default language code
  currentLangCode$ = this.currentLangCode.asObservable();

  setLangCode(langCode: string) {
    this.currentLangCode.next(langCode);
    _languageDefaultCode= this.storageService.getData("siteLanguage")?.split("-")[0] ??"tr";





      // Sayfayı yeniden yükle
      window.location.reload();
  }


  getLangCode(): string {
    return this.currentLangCode.getValue();
  }

  get languageDefaultCode(){
    return _languageDefaultCode;
  }
}

export let _languageDefaultCode:string="tr";
