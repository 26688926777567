import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { NewsEntity } from 'src/app/contracts/cms/news-entity';
import {
  LangService,
  _languageDefaultCode,
} from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { TranslationService } from 'src/app/services/translation.services';

declare var jQuery: any;
declare var services_slider: any;
@Component({
  selector: 'app-section-main-news',
  templateUrl: './section-main-news.component.html',
  styleUrls: ['./section-main-news.component.css'],
})
export class SectionMainNewsComponent implements OnInit, OnChanges {
  @Input() newsData: NewsEntity[] | any;
  data: NewsEntity | any;
  currentDate: Date = new Date();
  allNewsButton = '';
  allEventButton = '';
  allNoticeButton = '';

  guncel="(Güncellendi)"

  currentLang ="tr";
content="";
  constructor(private translationService: TranslationService,  private storageService: StorageService,) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['newsData'].currentValue) {
      this.data = changes['newsData'].currentValue;


    }

    if (this.data != null) {
      this.createSlider();
      this.content = this.truncateText( this.data[0].icerik.replace('<h1>&nbsp;</h1>', ''),200)
    }

    this.translationServices();
  }
  stripHtmlTags(htmlContent: string): string {
    const doc = new DOMParser().parseFromString(htmlContent, 'text/html');
    return doc.body.textContent || '';
  }
  ngOnInit(): void {
    this.translationServices();
  }
    truncateText(text: string, maxLength: number):string {

    if (text.length > maxLength) {
      return  text.replace('<h1>&nbsp;</h1>', '').substring(0, maxLength) + '[...]';
    } else {
      return text;
    }
  }
  translationServices() {
    const lang: 'en' | 'tr' = this.storageService.getData('siteLanguage')
    ?.split('-')[0] as 'en' | 'tr';

    this.currentLang = lang;

 this.guncel =  this.currentLang =="en" ? "(Updated)":"(Güncellendi)";
    this.allNewsButton=  this.translationService.getTranslation('allNewsButton', lang);
  }

  createSlider() {
    (function ($) {
      setTimeout(function () {
        services_slider();
      }, 300);
    })(jQuery);
  }

  transform(tarih: string): string {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);

    return splitDate[0].split('-')[2];
  }



  transformYil(tarih: string): string {
    if (!tarih || tarih.length <= 0) {
      return '';
    }
    const splitDate = tarih.split('T')[0].split('-');
    return splitDate[0]; // Yıl kısmı
  }
  ayYaz(tarih: string) {
    if (tarih.length <= 0) {
      return '';
    }
    var splitDate = tarih.split('T');

    var newTarih = new Date(splitDate[0]);
    const ayIsim = this.ayIsimAl(newTarih.getMonth());
    return ayIsim;
  }
  ayIsimAl(ayIndex: number): string {
    if (this.currentLang === "en") {
      switch (ayIndex) {
        case 0:
          return 'January';
        case 1:
          return 'February';
        case 2:
          return 'March';
        case 3:
          return 'April';
        case 4:
          return 'May';
        case 5:
          return 'June';
        case 6:
          return 'July';
        case 7:
          return 'August';
        case 8:
          return 'September';
        case 9:
          return 'October';
        case 10:
          return 'November';
        case 11:
          return 'December';
        default:
          return '';
      }
    } else {
      switch (ayIndex) {
        case 0:
          return 'Ocak';
        case 1:
          return 'Şubat';
        case 2:
          return 'Mart';
        case 3:
          return 'Nisan';
        case 4:
          return 'Mayıs';
        case 5:
          return 'Haziran';
        case 6:
          return 'Temmuz';
        case 7:
          return 'Ağustos';
        case 8:
          return 'Eylül';
        case 9:
          return 'Ekim';
        case 10:
          return 'Kasım';
        case 11:
          return 'Aralık';
        default:
          return '';
      }
    }
  }
}
