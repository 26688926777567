<div
  class="container-fluid plr-100 p-t30 p-b80 project-style-new site-bg-gray"
  *ngIf="notices != null"
>
  <div class="container-fluid">
    <div class="wt-separator-two-part">
      <div class="row wt-separator-two-part-row">
        <div class="col-xl-3 col-lg-12 col-md-12 col-7 wt-separator-two-part-left">
          <h3 style="margin-bottom: 0px">{{ title2 }}</h3>
        </div>
        <div class="col-xl-9 col-lg-12 col-md-12 col-4 text-right">




          <ul class="btn-filter-wrap hiz " >

            <li

              *ngFor="let noticeCategory of noticeCategories"
              [attr.data-filter2]="'200-' + noticeCategory.id"
              class="btn-filter2 d-none d-md-block custom-dp {{ noticeCategory.id ==2 ?'btn-active':''}}"

              (click)="filterNotices(noticeCategory.id)"
            >
              {{ noticeCategory.name }}
            </li>
            <li>
              <a
                routerLink="/kategori/duyurular/1"
                class="site-button site-btn-effect"
                >{{ all }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="section-content pd-custom">
    <div
      class="owl-carousel owl-carousel-filter notice-carousel owl-btn-vertical-center"
    >
      <div
        *ngFor="let noticex of filteredNotices"
        class="item item2 200-{{ noticex.categoryId }}"
      >
        <div class="wt-img-effect2 project-new-1">
          <div
            class="background-img"
            [ngStyle]="{
              'background-image': getBackgroundImage(noticex.manset)
            }"
          >
            <div class="overlay">
              <div class="post-content">
                <div *ngIf="currentLang!='en'" class="post-datex">
                  <i class="fa fa-calendar"></i>
                  <span>{{ transform(noticex.publisDate!=null ? noticex.publisDate: noticex.createdDate) }} </span>
                  <span> {{ ayYaz(noticex.publisDate!=null ? noticex.publisDate: noticex.createdDate) }} </span>
                  <span> {{ transformYil(noticex.publisDate!=null ? noticex.publisDate: noticex.createdDate) }}
                  </span>
                </div>
                <div class="project-new-content">
                  <h5 class="wt-title duyuru-title">
                    <a class="" routerLink="/duyuru/{{ noticex.url }}" class="">{{
                      noticex.baslik
                    }}</a>
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid plr-100" *ngIf="events != null && currentLang!='en'">
  <div class="row">
    <div class="col-md-12">
      <div class="section-full overlay-wraper bg-cover bg-no-repeat">
        <div *ngIf="overlay == 1" class="overlay-main opacity-09"></div>
        <div class="container-fluid">
          <div
            class="wt-separator-two-part"
            style="top: 0px; position: relative"
          >
            <div class="row wt-separator-two-part-row">
              <div class="col-lg-11 col-md-11 col-8 wt-separator-two-part-left">
                <div
                  class="section-head center wt-small-separator-outer text-left"
                >
                  <div class="wt-small-separator site-text-primary">
                    <h3 class="text-left">{{ title }}</h3>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-1 col-md-1 col-4"
              >
                <a
                  routerLink="/kategori/etkinlikler/1"
                  class="site-button site-btn-effect"
                  >{{ all }}</a
                >
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
                <div
                  class="masonry-wrap row mfp-gallery project-stamp clearfix d-flex justify-content-end"
                >
                  <div
                    class="stamp col-xl-12 col-lg-12 col-md-12 col-sm-12 m-b30 "
                  >
                    <div class="project-stamp-list bg-white " *ngIf="categories" >
                      <div class="filter-wrap hiz2  d-none d-md-block  custom-dp">
                        <ul
                          class="filter-navigation masonry-filter text-uppercase"
                        >
                          <li
                            (click)="filterEvents('*')"
                            class="btn-filter3 active d-none d-md-block"
                          >
                            <a
                              data-filter3="*"
                              data-hover="All"
                              href="javascript:void(0);"
                            >
                              <i class="flaticon-layers"></i>{{ all }}
                            </a>
                          </li>
                          <li
                            class="btn-filter3 d-none d-md-block"
                            *ngFor="let category of categories"
                          >
                            <a
                              [attr.data-filter3]="'100-' + category.id"
                              href="javascript:void(0);"
                              (click)="filterEvents(category.id)"
                            >
                              <i class="flaticon-layer"></i>{{ category.name }}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="section-content " *ngIf="events != null">
            <div class="owl-carousel event-carousel owl-btn-vertical-center">
              <div
                *ngFor="let event of filteredEvents"
                class="item3 wt-box bg-white cardbg animate_line  100-{{
                  event.category.id
                }}"
                attr.filter3="100-{{ event.category.id }}"
              >
                <a
                  routerLink="/etkinlik/{{ event.url }}"
                  class="text-white text-capitalize"
                >
                  <div class="latest-blog-3-pattern">
                    <div
                      class="blog-post latest-blog-3 overlay-wraper post-overlay large-date bg-cover bg-no-repeat bg-top-center"
                      [ngStyle]="{
                        'background-image': getBackgroundImage(event.manset)
                      }"
                    >
                      <div class="overlay-main opacity-09 bg-blue"></div>
                      <div class="wt-post-info p-a10 text-white">
                        <div class="post-overlay-position">
                          <div class="post-content-outer">
                            <div class="wt-post-meta m-b0">
                              <ul>
                                <li class="post-date">
                                  <strong
                                    >{{ transform(event.eventDateTime!=null ? event.eventDateTime: event.createdDate) }}
                                    {{ ayYaz(event.eventDateTime!=null ? event.eventDateTime: event.createdDate) }}</strong
                                  ><span>{{ transformYil(event.eventDateTime!=null ? event.eventDateTime: event.createdDate) }}</span>
                                </li>
                                <li *ngIf="!event.status" class="post-comment">
                                  <i class="fa fa-hourglass-end"></i>
                                </li>
                                <li class="post-author">
                                  <strong
                                    ><a class="btn-sm btn-danger">{{
                                      event.category.name
                                    }}</a></strong
                                  >
                                </li>
                              </ul>
                            </div>
                            <div class="wt-post-title">
                              <h5 class="post-title">{{ event.baslik }}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>

          <!-- <div class="divider"></div> -->
        </div>
      </div>
    </div>
  </div>
</div>
