<div class="page-wraper" [class.dark-mode]="isDarkMode">
  <!-- HEADER START -->
  <app-section-header4

  *ngIf="generalData?.generalSetting?.header"
    [headerData]="generalData?.generalSetting?.header ?? null"
    [navData]="generalData?.nav ?? null"
    [popupData]="generalData?.popup ?? null"
  ></app-section-header4>
  <!-- HEADER END -->

  <!-- CONTENT START -->
  <div class="page-content">
    <router-outlet> </router-outlet>
  </div>
  <!-- CONTENT END -->
  <!-- Harita Bileşenini Konumlandırma -->

  <!-- FOOTER START -->
  <app-section-footer1
  *ngIf="generalData?.footer"
    [footerData]="generalData?.footer ?? null"
    [navData]="generalData?.nav ?? null"
  ></app-section-footer1>

  <!-- FOOTER END -->
</div>

<app-loader></app-loader>
