import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FooterEntity } from 'src/app/contracts/cms/footer-entity';
import { MenuEntity } from 'src/app/contracts/cms/menu-entity';
import { LangService } from 'src/app/services/lang-service';
import { StorageService } from 'src/app/services/local-storage-serves';
import { TranslationService } from 'src/app/services/translation.services';

@Component({
  selector: 'app-section-footer1',
  templateUrl: './section-footer1.component.html',
  styleUrls: ['./section-footer1.component.css'],
})
export class SectionFooter1Component implements OnInit {
  @Input() footerData: FooterEntity | any;
  currentLang: string = 'tr-TR';

  searchValue: string = '';

  @Input() navData: MenuEntity[] | any;
  constructor(
    private langService: LangService,
    private router: Router,
    private storageService: StorageService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.langService.currentLangCode$.subscribe((langCode) => {
      this.currentLang = langCode;
    });

    this.translationServices();
  }
  search(): void {
    if (!this.searchValue.trim()) {
      // Input değeri boşsa işlemi durdur
      return;
    }

    // Arama URL'sini oluştur
    const searchUrl = '/arama/q=' + this.searchValue;

    // Yönlendirme işlemi
    this.router.navigateByUrl(searchUrl);
  }

  modalStatus = false;

  openModal() {
    this.modalStatus = !this.modalStatus;
  }

  footerText = '';
  translationServices() {
    const lang: 'en' | 'tr' = this.storageService
      .getData('siteLanguage')
      ?.split('-')[0] as 'en' | 'tr';

    this.footerText = this.translationService.getTranslation(
      'footerText',
      lang
    );
  }
}
